import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import Content, { HTMLContent, ContentType } from '../components/Content';
import Container from 'react-bootstrap/Container';

export const StandardPageTemplate = ({ title, content, contentComponent }: StandardPageTemplateType) => {
  const PageContent = contentComponent || Content;

  return (
    <Container>
      <h1>{title}</h1>
      <PageContent className="content" content={content} />
    </Container>
  );
};

type StandardPageTemplateType = {
  title: string,
  content: string,
  contentComponent?: { (data: ContentType): any },
}

const StandardPage = ({ data }: StandardPageType) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <StandardPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
      />
    </Layout>
  );
};

type StandardPageType = {
  data: {
    markdownRemark: {
      html: string,
      frontmatter: {
        title: string,
      },
    },
  },
};

export default StandardPage;

export const standardPageQuery = graphql`
  query StandardPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`;
